import black_white_spiral from "./home/black_white_spiral.webp";
import blue_eye from "./home/blue_eye.webp";
import logo from "./logo.svg";
import main_animation from "./home/main_animation.gif";
import main_animation_video from "./home/main_animation.webm";
import line from "./line.svg";
import close_button from "./close_button.svg";
import download_modal_bg from "./download_modal_bg.webp";
import download_form_modal_bg from "./download_form_modal_bg.webp";

import instagram from "./instagram.svg";
import mail from "./mail.svg";
import insta_icon from "./insta_icon.svg";
import email_icon from "./email_icon.svg";

import background_audio from "./background-audio.mp3";
import ding from "./ding.mp3";

import loading from "./loading.gif";

import quote_left from "./quote_left.svg";
import quote_right from "./quote_right.svg";
import go_back from "./go_back.svg";

// import speaker from "./speaker.svg";
// import speaker_off from "./speaker_off.svg";

const speaker_off = "data:image/svg+xml;utf8;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDQ0IiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwNDUiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTA0NiI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI4OCIgaGVpZ2h0PSIyODgiPjxnIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgZGF0YS1uYW1lPSJ2b2x1bWUtb2ZmIj48cGF0aCBkPSJtMTYuOTEgMTQuMDggMS40NCAxLjQ0YTYgNiAwIDAgMC0uMDctNy4xNSAxIDEgMCAxIDAtMS41NiAxLjI2IDQgNCAwIDAgMSAuMTkgNC40NXoiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJjb2xvcjAwMCBzdmdTaGFwZSI+PC9wYXRoPjxwYXRoIGQ9Ik0yMSAxMmE2LjUxIDYuNTEgMCAwIDEtMS43OCA0LjM5bDEuNDIgMS40MkE4LjUzIDguNTMgMCAwIDAgMjMgMTJhOC43NSA4Ljc1IDAgMCAwLTMuMzYtNi43NyAxIDEgMCAxIDAtMS4yOCAxLjU0QTYuOCA2LjggMCAwIDEgMjEgMTJ6bS02IC4xN1Y0YTEgMSAwIDAgMC0xLjU3LS44M0w5IDYuMnpNNC43NCA3LjU3SDJhMSAxIDAgMCAwLTEgMXY2Ljg2YTEgMSAwIDAgMCAxIDFoNWw2LjQxIDQuNEExLjA2IDEuMDYgMCAwIDAgMTQgMjFhMSAxIDAgMCAwIDEtMXYtMi4xN3ptLS4wMy00LjI4YTEgMSAwIDAgMC0xLjQyIDEuNDJsMTYgMTZhMSAxIDAgMCAwIDEuNDIgMCAxIDEgMCAwIDAgMC0xLjQyeiIgZmlsbD0iI2ZmZmZmZiIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48L3BhdGg+PC9nPjwvZz48L3N2Zz48L2c+PC9zdmc+";

const speaker = "data:image/svg+xml;utf8;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDMxIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwMzIiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTAzMyI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIGNsaXAtcnVsZT0iZXZlbm9kZCIgdmlld0JveD0iMCAwIDUwMCA1MDAiIHdpZHRoPSIyODgiIGhlaWdodD0iMjg4Ij48cGF0aCBkPSJNMzE2Ljg1NCA0OC4yNDVhNy41IDcuNSAwIDAgMC0xMS4xNzctNi41MzdsLTE3MC4yNDggOTUuNzY1YTcuNDk4IDcuNDk4IDAgMCAwLTMuODIzIDYuNTM2djIxMS45ODJhNy41IDcuNSAwIDAgMCAzLjgyMyA2LjUzN2wxNzAuMjQ4IDk1Ljc2NGE3LjUgNy41IDAgMCAwIDExLjE3Ny02LjUzN1Y0OC4yNDVabTc1LjIgOTMuNTg5YzI4LjM0NiAzNi42ODUgNDMuMTc2IDczLjA0NSA0My4zMzggMTA5LjIyNC4xNjEgMzYuMDAxLTE0LjI2MyA3MS41NjQtNDMuMTE2IDEwNi44MjktNC40NzcgNS40NzMtMy42NjkgMTMuNTUyIDEuODA0IDE4LjAzIDUuNDczIDQuNDc3IDEzLjU1MiAzLjY2OSAxOC4wMy0xLjgwNCAzMy4xOS00MC41NjggNDkuMDkyLTgxLjc1NSA0OC45MDctMTIzLjE3LS4xODUtNDEuMjM4LTE2LjM3Ni04Mi45NjItNDguNjg1LTEyNC43NzctNC4zMjQtNS41OTUtMTIuMzc3LTYuNjI4LTE3Ljk3My0yLjMwNS01LjU5NSA0LjMyNC02LjYyOCAxMi4zNzctMi4zMDUgMTcuOTczWm0tMjgyLjA2MSA1LjI4OWE3LjUgNy41IDAgMCAwLTcuNS03LjVINDYuNDgxYTcuNDk5IDcuNDk5IDAgMCAwLTcuNSA3LjV2MjA1Ljc1NGE3LjUwMSA3LjUwMSAwIDAgMCA3LjUgNy41aDU2LjAxMmE3LjUwMSA3LjUwMSAwIDAgMCA3LjUtNy41VjE0Ny4xMjNabTIzNC4yNTcgNDQuNTQyYzI3Ljc2MiA0NS41ODMgMjcuNjk4IDgzLjY1IDEuMTU2IDExNS4wNjctNC41NjQgNS40MDEtMy44ODMgMTMuNDkyIDEuNTE4IDE4LjA1NSA1LjQwMiA0LjU2NCAxMy40OTMgMy44ODMgMTguMDU2LTEuNTE4IDMzLjQzMS0zOS41NzEgMzYuMTIzLTg3LjUyIDEuMTU2LTE0NC45MzQtMy42NzktNi4wMzktMTEuNTY4LTcuOTU2LTE3LjYwOC00LjI3OC02LjAzOSAzLjY3OS03Ljk1NiAxMS41NjgtNC4yNzggMTcuNjA4WiIgZmlsbD0iI2ZmZmZmZiIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48L3BhdGg+PC9zdmc+PC9nPjwvc3ZnPg==";


export {
    black_white_spiral,
    blue_eye,
    logo,
    main_animation,
    main_animation_video,
    line,
    close_button,
    download_modal_bg,
    download_form_modal_bg,
    instagram,
    mail,
    speaker,
    speaker_off,
    background_audio,
    loading,
    ding,
    insta_icon,
    email_icon,
    quote_left,
    quote_right,
    go_back
}