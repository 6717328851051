import React, { forwardRef, useEffect, useState } from 'react';
import styles from "./Countdown.module.css";
import { line } from 'assets';
import AvailableIn from 'components/Common/AvailableIn';
import Footer from 'components/Common/Footer';
// import AvailableIn from 'components/Common/AvailableIn';

function getTimeRemaining(targetDate) {
    const total = Date.parse(targetDate) - Date.now(); // Calculate total milliseconds remaining
    if (total <= 0) return '00:00:00:00'; // If target date has passed, return all zeros

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return `${formatTime(days)}:${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
}

function formatTime(time) {
    return time < 10 ? `0${time}` : time; // Add leading zero if time is less than 10
}

const SpannedTime = ({ str }) => {
    let splitted = str.split(":");

    return (<>
        {
            splitted?.map?.((s, i) => {
                return <div key={i} className='d-flex justify-content-center align-items-center'>
                    <span className={`d-flex justify-content-center align-items-center ${styles['span-time']}`}>
                        {s}
                    </span>
                    {
                        i < splitted.length - 1 ? <span>:</span> : <></>
                    }
                </div>
            })
        }
    </>);
}

const targetDate = new Date('2024-06-10T19:00:00');

const Countdown = forwardRef((props, ref) => {
    const { className } = props;

    const [remainingTime, setRemainingTime] = useState("43:23:15:00");

    useEffect(() => {

        const intervalId = setInterval(() => {
            const remTime = getTimeRemaining(targetDate);
            setRemainingTime(remTime);
        });

        return () => clearInterval(intervalId);

    }, []);

    return (
        <>
            <section ref={ref} className={`d-flex justify-content-center align-items-center ${styles.container} ${className}`}>
                <div className={`d-flex flex-column justify-content-center align-items-center ${styles['text-container']}`}>
                    <div>
                        <h5 className='mb-5 text1'>Coming Soon</h5>
                        <h5 className='text4 text-center'>LORIUM IPSUM</h5>
                    </div>
                    <div>
                        <h2 className='d-flex justify-content-center align-items-center'>
                            <SpannedTime str={remainingTime} />
                        </h2>
                    </div>
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                        <img src={line} alt="line" style={{ transform: "rotate(180deg)" }} />
                        <p className='mb-0 text-center text5'>Lorem ipsum dolor sit amet nom</p>
                        <img src={line} alt="line" />
                    </div>
                    <div style={{
                        border: "1px solid rgba(255, 255, 255, 0.7)",
                        borderRadius: "7px",
                        width: "50%"
                    }}>
                        <p className='mb-0 text-center text1' style={{
                            padding: "10px 40px",
                            textTransform: "uppercase"
                        }}>
                            coming soon
                        </p>
                    </div>
                </div>
            </section>
            {/* <AvailableIn /> */}
            <Footer />
        </>
    )
});

export default Countdown;