import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styles from "./DownloadModal.module.css";
import { close_button, ding, go_back } from 'assets';
import { Spinner } from 'react-bootstrap';
import { asyncStatuses } from 'store/enums';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParentContext } from 'store/ParentContext';
import { capitalizeFirstThreeCharacters } from 'pages/Quotes/QuotesPage';
import { validatedEmail } from 'utilities/validateEmail';
import { NoEmailMessage, NoNameMessage } from 'pages/ShowInterest/ShowInterestPage';


const DownloadModal = forwardRef(({ className, hideDownloadModal }, ref) => {

    const { resetAnimation } = useParentContext();

    const showConfirmationModal = useRef(null);
    const downloadFormModal = useRef(null);

    const [confirmationModalClassNames, setConfirmationModalClassNames] = useState("d-none");

    const closeModal = () => {
        // showConfirmationModal.current?.classList.remove('d-none');
        setConfirmationModalClassNames("");
    }

    const hideConfirmationModal = () => {
        // showConfirmationModal.current?.classList.add('fade-out');
        setConfirmationModalClassNames(prev => prev.concat(" fade-out"));
        setTimeout(() => {
            // showConfirmationModal.current?.classList.add('d-none');
            // showConfirmationModal.current.classList.remove('fade-out');
            setConfirmationModalClassNames('d-none');
        }, 700);
    }

    const showDownloadFormModal = () => {
        downloadFormModal.current?.classList.remove('d-none');
        setTimeout(() => {
            ref.current?.classList.add('d-none');
        }, 300);
    }

    const hideDownloadFormModalAndShowDownloadModal = () => {
        downloadFormModal.current?.classList.add('d-none');
        setTimeout(() => {
            ref.current?.classList.remove('d-none');
        }, 300);
    }

    const goToSpiral = () => {
        resetAnimation();
    }

    return (
        <>
            <section
                ref={ref}
                className={`d-flex align-items-center ${styles.container} ${styles.download_modal_bg} ${className}`}
            >
                <div className={`cursor-pointer ${styles['back-button-container']}`} onClick={goToSpiral}>
                    <img src={go_back} alt="Back" />
                </div>
                <div className={`cursor-pointer ${styles['close-button-container']}`} onClick={closeModal}>
                    <img src={close_button} alt="Close Download" />
                </div>
                <div className={`d-flex flex-column justify-content-center align-items-center ${styles['text-container']} ${styles['text-container-gap']}`}>
                    <div>
                        <h4 className='text6'
                            dangerouslySetInnerHTML={{
                                __html: capitalizeFirstThreeCharacters("Curious About Your Partner’s Thoughts?")
                            }}
                        ></h4>
                    </div>
                    <div className={`${styles['limited-period-offer']}`}>
                        <h4 className='text7'
                            dangerouslySetInnerHTML={{
                                __html: capitalizeFirstThreeCharacters("Limited Period Offer")
                            }}
                        ></h4>
                    </div>
                    <div>
                        <p className='text-center text7'
                            dangerouslySetInnerHTML={{
                                __html: capitalizeFirstThreeCharacters("Dive into the Confluence of Consciousness and uncover the symphony of hidden thoughts. Unlock the secrets of the mind and discover profound insights on every page.")
                            }}
                        >
                        </p>
                    </div>
                    <div onClick={showDownloadFormModal} className='cursor-pointer'>
                        <h5 className='text1'>
                            Download now for FREE!
                        </h5>
                        <div className={`faded-underline`}></div>
                    </div>
                </div>
                <ConfirmationModal ref={showConfirmationModal} hideConfirmationModal={hideConfirmationModal} hideDownloadModal={hideDownloadModal} className={confirmationModalClassNames} />
            </section>
            <DownloadFormModal ref={downloadFormModal} hideDownloadModal={hideDownloadModal} className={`d-none`} hideDownloadFormModalAndShowDownloadModal={hideDownloadFormModalAndShowDownloadModal} />
        </>
    )
});

const ConfirmationModal = forwardRef(({ hideConfirmationModal, hideDownloadModal, className }, ref) => {

    const yesNoRef = useRef(null);

    const removeUnderlineFromBoth = () => {
        if (yesNoRef.current.children) {
            for (let child of yesNoRef.current.children) {
                child?.children?.[0].classList?.remove?.('faded-underline-animated');
            }
        }
    }

    const addDefaultNoUnderline = () => {
        if (yesNoRef.current.children) {
            for (let child of yesNoRef.current.children) {
                if (child.getAttribute?.('data-action') === "no") {
                    child.children?.[0].classList?.add?.('faded-underline-animated');
                }
            }
        }
    }

    const mouseIn = (e) => {
        removeUnderlineFromBoth();
        e?.target?.children?.[0].classList?.add?.('faded-underline-animated');
    }

    const mouseOut = (e) => {
        removeUnderlineFromBoth();
        e?.target?.children?.[0].classList?.remove?.('faded-underline-animated');
        // addDefaultNoUnderline();
    }

    const onClick = (e) => {
        if (e?.target?.getAttribute?.('data-action') === "no") {
            // console.log("No action");
            hideConfirmationModal();
        } else if (e?.target?.getAttribute?.('data-action') === "yes") {
            // console.log("Yes action");
            hideConfirmationModal();
            hideDownloadModal();
        }
    }

    useEffect(() => {
        addDefaultNoUnderline();
    }, [className]);

    return (
        <div ref={ref} className={`d-flex flex-column justify-content-end align-items-center gap-3 ${styles['confirmation-modal-container']} ${className}`}>
            <div style={{ maxWidth: 545 }}>
                <p className='mb-4 text7'
                    dangerouslySetInnerHTML={{
                        __html: capitalizeFirstThreeCharacters("Are you sure you aren’t curious about your partner’s thoughts?")
                    }}
                >

                </p>
            </div>
            <div ref={yesNoRef} onClick={onClick} className={`d-flex justify-content-between align-items-center`} style={{ width: "25%" }}>
                <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} data-action="no" className='position-relative cursor-pointer d-flex justify-content-center align-items-center text1' style={{ width: 90 }}>
                    No
                    <div className='faded-underline-animated'></div>
                </div>
                <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} data-action="yes" className='position-relative cursor-pointer d-flex justify-content-center align-items-center text1' style={{ width: 90 }}>
                    Yes
                    <div></div>
                </div>
            </div>
        </div>
    );
});

const DownloadFormModal = forwardRef(({ className, hideDownloadModal, hideDownloadFormModalAndShowDownloadModal }, ref) => {

    const notificationAudioRef = useRef(null);
    const { setUserDownloadedPdf } = useParentContext();

    const [name, setName] = useState("");
    const [isNameValid, setIsNameValid] = useState(null);
    const [errorNameMessage, setErrorNameMessage] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [errorEmailMessage, setErrorEmailMessage] = useState("");

    const [submitStatus, setSubmitStatus] = useState(null);
    const [submitFailCount, setSubmitFailCount] = useState(0);

    const changeName = ({ target: { value } }) => {
        // console.log(value);
        setName(value);
        if (!value) {
            setIsNameValid(false);
            setErrorNameMessage(NoNameMessage);
            return;
        } else {
            setIsNameValid(true);
            setErrorNameMessage("");
        }
    }

    const changeEmail = ({ target: { value } }) => {
        console.log(value, !value);
        setEmail(value);
        if (!value) {
            setErrorEmailMessage(NoEmailMessage);
            setIsEmailValid(false);
            return;
        } else {
            let e = validatedEmail(value);
            if (!e) {
                setErrorEmailMessage(`Email cannot be ${value}`);
                setIsEmailValid(false);
                return;
            } else {
                setIsEmailValid(true);
                setErrorEmailMessage("");
            }
        }
    }

    const downloadFile = () => {
        const fileName = 'Sensual Synchronicity_Website Exclusive.pdf'; // Replace with your file name
        const filePath = `./${fileName}`;

        fetch(filePath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                const pdfBlob = new Blob([blob], { type: "application/pdf" });
                const url = window.URL.createObjectURL(pdfBlob);

                if (isSafari) {
                    // const newTab = window.open(url, '_blank');
                    // window.URL.revokeObjectURL(url);
                    // if (newTab) {
                    //     newTab.location = url;
                    // }
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('target', '_blank');
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }

                setUserDownloadedPdf(true);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    const submitForm = async (event) => {
        event.preventDefault();
        // console.log({ name, email, number });

        let n = name;
        let e = validatedEmail(email);
        // let p = validatedNumber(number);

        if (!n) {
            // const res = <div dangerouslySetInnerHTML={{
            //   __html: capitalizeFirstThreeCharacters('Please share your name with us.')
            // }}>
            // </div>
            // toast.error(res);
            setIsNameValid(false);
            setErrorNameMessage(NoNameMessage);
            return;
        } else {
            setIsNameValid(true);
            setErrorNameMessage("");
        }

        if (!email) {
            // const res = <div dangerouslySetInnerHTML={{
            //   __html: capitalizeFirstThreeCharacters('Provide your email for updates.')
            // }}>
            // </div>
            // toast.error(res);
            setIsEmailValid(false);
            setErrorEmailMessage(NoEmailMessage);
            return;
        } else {
            setIsEmailValid(true);
            setErrorEmailMessage("");
        }

        if (!e) {
            // const res = <div dangerouslySetInnerHTML={{
            //   __html: capitalizeFirstThreeCharacters(`Email cannot be ${email}`)
            // }}>
            // </div>
            // toast.error(res);
            setErrorEmailMessage(`Email cannot be ${email}`);
            setIsEmailValid(false);
            return;
        } else {
            setIsEmailValid(true);
            setErrorEmailMessage("");
        }

        setSubmitStatus(asyncStatuses.LOADING);
        try {
            const response = await axios.post("/confluence/downloads/", { email, name });
            // console.log(response);
            const successResponse = <div dangerouslySetInnerHTML={{
                __html: capitalizeFirstThreeCharacters('Enjoy the free and exclusive chapter!')
            }}>
            </div>
            toast.success(successResponse);
            notificationAudioRef.current.play();
            downloadFile();
            hideDownloadFormModal();
            setSubmitStatus(asyncStatuses.SUCCESS);
        } catch (err) {
            console.error(err);
            toast.error("We're sorry. Something went wrong.");
            setSubmitStatus(asyncStatuses.FAILED);
            setSubmitFailCount(prev => prev + 1);
        }
    }

    const hideDownloadFormModal = () => {
        ref.current?.classList.add('d-none');
        hideDownloadModal();
    }

    return (
        <section
            ref={ref}
            className={`d-flex align-items-center ${styles.container} ${styles.download_form_modal_bg} ${className}`}
        >
            <div className={`cursor-pointer ${styles['back-button-container']}`} onClick={hideDownloadFormModalAndShowDownloadModal}>
                <img src={go_back} alt="Back" />
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center ${styles['text-container']} ${styles['text-container-gap']}`}>
                {
                    submitFailCount > 2 ? <div className={`cursor-pointer ${styles['close-button-container']}`} onClick={hideDownloadFormModal}>
                        <img src={close_button} alt="Close Download" />
                    </div> : <></>
                }
                <div className={`d-flex flex-column w-100`}>
                    {/* <h4 className='text6'
                        dangerouslySetInnerHTML={{
                            __html: capitalizeFirstThreeCharacters("Unlock the Secrets of the Mind: Discover Your Partner's True Thoughts!")
                        }}
                    ></h4> */}
                    <h1 className='text-center mt-4 px-4 text1' style={{ color: "#00B4D8" }}>
                        <strong>Ent</strong>er <strong>you</strong>r <strong>nam</strong>e and <strong>ema</strong>il to <strong>Dow</strong>nload the <span style={{
                            color: "#FF0000"
                        }}><strong>Exc</strong>lusive</span> <strong>cha</strong>pter for <span style={{
                            color: "#FF0000"
                        }}><strong>FRE</strong>E</span>
                    </h1>
                </div>
                <form onSubmit={submitForm} className='w-100'>
                    <div className={`d-flex flex-column justify-content-between align-items-center w-100 ${styles['text-container-gap']}`}>
                        <div className='d-flex flex-column justify-content-start align-items-center w-100'>
                            <h2 className='text1' htmlFor="name">Name</h2>
                            <input className={`${styles['input']}`} type="text" name='name' required value={name} onChange={changeName} style={isNameValid === false ? { borderColor: "red", width: "70%" } : { width: "70%" }} />
                            {/* {
                                isNameValid === false ? <small className="error-message" dangerouslySetInnerHTML={{
                                    __html: capitalizeFirstThreeCharacters(errorNameMessage)
                                }}></small> : <></>
                            } */}
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                            <h2 className='text1' htmlFor="name">Email</h2>
                            <input className={`${styles['input']}`} type="email" name='email' required value={email} onChange={changeEmail} style={isEmailValid === false ? { borderColor: "red", width: "70%" } : { width: "70%" }} />
                            {/* {
                                isEmailValid === false ? <small className="error-message" dangerouslySetInnerHTML={{
                                    __html: capitalizeFirstThreeCharacters(errorEmailMessage)
                                }}></small> : <></>
                            } */}
                        </div>
                        <div className='py-5'>
                            <>
                                {
                                    submitStatus === asyncStatuses.LOADING ?
                                        <button disabled className='d-flex gap-2 justify-content-center align-items-center'>
                                            <h5 className='cursor-pointer text1' style={{ color: "#bcbcbc", textTransform: "uppercase" }}>Downloading</h5>
                                            <Spinner size='sm' />
                                        </button>
                                        :
                                        <button className='d-flex justify-content-center align-items-center w-100' type='submit'>
                                            <h5 style={{ textTransform: "uppercase" }} className='cursor-pointer text1'>
                                                Download now for FREE!
                                            </h5>
                                        </button>
                                }
                            </>
                            <div className={`faded-underline`}></div>
                        </div>
                    </div>
                </form>
            </div>
            <audio ref={notificationAudioRef}>
                <source src={ding} />
                Your browser does not support the audio element.
            </audio>
        </section>
    )
});

export default DownloadModal