import React, { useLayoutEffect, useState } from 'react';
import styles from './Footer.module.css';
import { email_icon, insta_icon, line, logo } from 'assets';
import { Link, useLocation } from 'react-router-dom';
import { useParentContext } from 'store/ParentContext';

const Footer = () => {

    const { isAnimationOver } = useParentContext();
    
    const [classNames, setClassNames] = useState("");

    const location = useLocation();
    const { pathname } = location;

    useLayoutEffect(() => {
        if (pathname === "/" && !isAnimationOver) {
            setClassNames("d-none");
        } else {
            setClassNames("");
        }
    }, [pathname, isAnimationOver]);

    return (
        <footer className={`${styles.footer} ${classNames}`}>
            <div className={styles.container}>
                <div className={styles.logoSection}>
                    <a href={"/"} className='cursor-pointer'>
                        <img src={logo} alt="Logo" className={styles.logo} />
                    </a>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center gap-4 py-4'>
                    <div className='d-flex justify-content-center align-items-center gap-4'>
                        <img src={line} className={`${styles['line']}`} alt="line" style={{ transform: "rotate(180deg)" }} />
                        <h3 className='felix-font-family text3 confluence_text footer_heading_text'>The Confluence Of Consciousness</h3>
                        <img src={line} className={`${styles['line']}`} alt="line" />
                    </div>
                    <div className={`${styles.menuSection}`}>
                        <div className={`${styles.menuItem}`}>
                            <Link to={"/"} className='footer_body_text'>Home</Link>
                        </div>
                        <div className={`${styles.menuItem} footer_body_text`}>
                            <Link to={"/quotes"} className='footer_body_text'>Quotes</Link>
                        </div>
                        <div className={`${styles.menuItem} footer_body_text`}>
                            <Link to={"/show-interest"} className='footer_body_text'>I Am Interested</Link>
                        </div>
                    </div>
                    <div className={styles.socialSection}>
                        <div className={styles.socialIcon}>
                            <a href="https://www.instagram.com/confluenceofconsciousness/" target='_blank' rel='noreferrer'>
                                <img src={insta_icon} alt="Instagram" className={styles.icon} />
                            </a>
                        </div>
                        <div className={styles.socialIcon}>
                            <a href="mailto:sharedfuture@confluenceofconsciousness.com" target='_blank' rel='noreferrer'>
                                <img src={email_icon} alt="Email" className={styles.icon} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`${styles.copyright} footer_body_text`}>
                Copyright © Xyz, Inc
            </div> */}
        </footer>
    );
};

export default Footer;
