import { background_audio } from "assets";
import { MUTED_VALUES } from "components/Common/AvailableIn";
import { useLocation } from "react-router-dom";
import { FULL_WEBSITE_BG, NAVBAR_ID } from "utilities/selectors";
import homeLoaderStyles from "../pages/Home/HomeLoader.module.css";
import { EYE_IMAGE_ID, H1_ID, SPIRAL_IMAGE_ID } from 'utilities/selectors';

const { createContext, useState, useContext, useRef } = require("react");

const ParentContext = createContext();

export const DEFAULT_OPACITY = 1;
export const LS_KEY_FOR_USER_DOWNLOADED_PDF = "p";

export const showNavbar = () => {
    const navbar = document.querySelector(`#${NAVBAR_ID}`);
    const bg_img = document.querySelector(`#${FULL_WEBSITE_BG}`);
    if (navbar && bg_img) {
        navbar.classList.remove('d-none');
        bg_img.classList.remove('d-none');
    }
}

export const ParentContextProvider = ({ children }) => {

    const audioRef = useRef(null);
    const downloadModalRef = useRef(null);
    const HomePageRef = useRef(null);
    const downloadModalIntervalRef = useRef(null);
    const spiral_img_ref = useRef(null);

    const timeoutRef = useRef(null);
    const loaderContainerRef = useRef(null);
    const tapAnywhereTextRef = useRef(null);

    const resetAnimation = () => {
        tapAnywhereTextRef?.current?.classList?.remove(`${homeLoaderStyles['scale-down']}`);
        spiral_img_ref.current?.classList?.remove?.(`${homeLoaderStyles.active}`);
        clearTimeout(timeoutRef.current);
        let el = document.querySelector(`#${EYE_IMAGE_ID}`);
        if (el) {
            el.classList.remove(`${homeLoaderStyles.active_animation_1}`);
        }
        el = document.querySelector(`#${SPIRAL_IMAGE_ID}`);
        if (el) {
            el.classList.remove('d-none');
            // setOpacityForAvailableIn(0);
        }
        let h1 = document.querySelector(`#${H1_ID}`);
        let eye = document.querySelector(`#${EYE_IMAGE_ID}`);
        if (h1 && eye) {
            h1.classList.remove(`${homeLoaderStyles.active}`);
            h1.classList.add(`text-black`);
            h1.classList.add(`bg-black`);
            eye.classList.remove(`${homeLoaderStyles.active_animation_2}`);
        }
        downloadModalRef.current?.classList?.add?.("d-none");
        loaderContainerRef.current.classList.remove(`${homeLoaderStyles.fade_out}`);
    }

    const [isAnimationOver, setIsAnimationOver] = useState(false);
    const [navbarShouldBeVisible, setNavbarVisibility] = useState(false);
    const [opacityForAvailableIn, setOpacityForAvailableIn] = useState(DEFAULT_OPACITY);
    const [isMuted, setIsMuted] = useState(MUTED_VALUES.MUTED_BY_DEFAULT);

    const setUserDownloadedPdf = (value) => {
        localStorage.setItem(LS_KEY_FOR_USER_DOWNLOADED_PDF, !!value);
        clearInterval(downloadModalIntervalRef.current);
        console.log("cleared-");
    }

    const playAudio = (unmute_by_user) => {
        const allowedToPlay = isMuted === MUTED_VALUES.MUTED_BY_DEFAULT || unmute_by_user === true;
        console.log({ isMuted, allowedToPlay, unmute_by_user, v: isMuted === MUTED_VALUES.MUTED_BY_DEFAULT })
        if (allowedToPlay) {
            audioRef.current.play().then(() => {
                setIsMuted(false);
                console.log("UnMuted");
            }).catch((e) => console.log("couldn't play the audio", e));
        }
    }

    const pauseAudio = (muted_by_user) => {
        audioRef.current.pause();
        if (muted_by_user) {
            setIsMuted(MUTED_VALUES.MUTED_BY_USER)
        } else {
            setIsMuted(MUTED_VALUES.MUTED_BY_DEFAULT);
        }
        console.log("Muted");
    }

    const hideDownloadModal = () => {
        downloadModalRef.current?.classList?.add('fade-out');
        HomePageRef.current?.classList?.remove?.("d-none");
        showNavbar();
        setTimeout(() => {
            downloadModalRef.current?.classList.add('d-none');
            downloadModalRef.current.classList.remove('fade-out');
            if (window.location.pathname === "/") {
                setIsAnimationOver(true);
            }
        }, 700);
    }

    return <ParentContext.Provider value={{
        isAnimationOver,
        setIsAnimationOver,
        navbarShouldBeVisible,
        setNavbarVisibility,
        opacityForAvailableIn,
        setOpacityForAvailableIn,
        isMuted,
        setIsMuted,
        playAudio,
        pauseAudio,
        downloadModalRef,
        HomePageRef,
        hideDownloadModal,
        setUserDownloadedPdf,
        downloadModalIntervalRef,
        spiral_img_ref,
        timeoutRef,
        loaderContainerRef,
        tapAnywhereTextRef,
        resetAnimation
    }} >
        {children}
        <audio autoPlay loop ref={audioRef}>
            <source src={background_audio} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </ParentContext.Provider>
}

export const useParentContext = () => useContext(ParentContext);