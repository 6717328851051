import React, { useEffect, useRef, useState } from 'react';
import { background_audio, instagram, mail, speaker, speaker_off } from 'assets';
import styles from "./AvailableIn.module.css";
import { useParentContext } from 'store/ParentContext';
import { useLocation } from 'react-router-dom';

export const MUTED_VALUES = {
    MUTED_BY_USER: "MBU",
    MUTED_BY_DEFAULT: "MBD",
    UNMUTED: "UM"
}


const DEFAULT_TEXT = "available in";


const AvailableIn = ({ showSpeaker = true, showInstagram = true, showMail = true, text = DEFAULT_TEXT }) => {

    const [_text, setText] = useState(text);

    const { isAnimationOver, opacityForAvailableIn, isMuted, playAudio, pauseAudio } = useParentContext();
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (showSpeaker) {
                if (pathname === "/") {
                    if (isAnimationOver) {
                        playAudio();
                    } else {
                        // pauseAudio();
                        playAudio();
                    }
                } else {
                    playAudio();
                }
            }
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [isAnimationOver, showSpeaker, pathname]);

    useEffect(() => {
        if (_text === DEFAULT_TEXT) {
            if (pathname === "/") {
                if (isAnimationOver) {
                    setText(text);
                } else {
                    setText("Best Experience")
                }
            }
        } else {
            if (isAnimationOver) {
                setText(text);
            } else {
                setText("Best Experience")
            }
        }
    }, [_text, isAnimationOver, pathname, text]);

    const toggleMute = () => {
        if (isMuted) {
            playAudio(true);
        } else {
            pauseAudio(true);
        }
    };


    return (
        <div 
            className={`d-flex flex-column flex-md-row justify-content-between align-items-center gap-3 gao-md-5 ${styles['container']}`}
            style={{
                opacity: opacityForAvailableIn,
            }}
        >
            <div>
                <p className='text1' style={{ textTransform: "capitalize" }}>
                    {_text}
                </p>
            </div>
            {
                (pathname !== "/" || _text === DEFAULT_TEXT) ? <div className={`d-flex justify-content-center align-items-center gap-4`}>
                    {showInstagram && <div className={`d-flex justify-content-center align-items-center ${styles['image-container']}`}>
                        <a href="https://www.instagram.com/confluenceofconsciousness/" target='_blank' rel='noreferrer'>
                            <img src={instagram} alt="Instagram" />
                        </a>
                    </div>}
                    {showMail && <div className={`d-flex justify-content-center align-items-center ${styles['image-container']}`}>
                        <a href="mailto:sharedfuture@confluenceofconsciousness.com" target='_blank' rel='noreferrer'>
                            <img src={mail} alt="mail" />
                        </a>
                    </div>}
                    {showSpeaker && <div onClick={toggleMute} className={`d-flex justify-content-center align-items-center ${styles['image-container']} ${styles['sound-indicator']}`}>
                        <img src={isMuted ? speaker_off : speaker} alt="speaker" />
                    </div>}
                </div> : <div className={`d-flex justify-content-center align-items-center gap-4`}>
                    {showSpeaker && <div onClick={toggleMute} className={`d-flex justify-content-center align-items-center ${styles['image-container']}`}>
                        <img src={isMuted ? speaker_off : speaker} alt="speaker" />
                    </div>}
                </div>
            }
            
        </div>
    )
}

export default AvailableIn;