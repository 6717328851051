import React, { forwardRef, useEffect } from 'react';
import styles from "./Home.module.css";
import { Link } from 'react-router-dom';
import AvailableIn from 'components/Common/AvailableIn';
import { DEFAULT_OPACITY, useParentContext } from 'store/ParentContext';
import Footer from 'components/Common/Footer';
// import AvailableIn from 'components/Common/AvailableIn';

const Home = forwardRef((props, ref) => {
    const { className } = props;

    const { setOpacityForAvailableIn } = useParentContext();

    useEffect(()=>{
        setOpacityForAvailableIn(DEFAULT_OPACITY);
    }, [className, setOpacityForAvailableIn]);

    return (
        <>
            <section ref={ref} className={`d-flex justify-content-center align-items-center ${styles.container} ${className}`}>
                <div className={`d-flex flex-column justify-content-center align-items-center ${styles['text-container']}`}>
                    <div>
                        <h5 className='text1'>Coming Soon</h5>
                    </div>
                    <div>
                        <h3 className='text2 felix-font-family confluence_text' >The Confluence Of Consciousness</h3>
                    </div>
                    <div>
                        <h5 className='text1'>
                            <Link to={"/quotes"}>Quotes</Link>
                        </h5>
                        <div className={`faded-underline`}></div>
                    </div>
                </div>
            </section>
            {/* <AvailableIn text={"Visit Us"} /> */}
            <Footer />
        </>
    )
});

export default Home