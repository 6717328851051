import React, { useEffect, useRef, useState } from 'react';
import styles from "./ShowInterest.module.css";
import { line } from 'assets';
import { toast } from 'react-toastify';
import { asyncStatuses } from 'store/enums';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Footer from 'components/Common/Footer';
import { validatedEmail } from 'utilities/validateEmail';
import { capitalizeFirstThreeCharacters } from 'pages/Quotes/QuotesPage';

export const NoNameMessage = "Please share your name with us.";
export const NoEmailMessage = "Provide your email for updates.";

const GAP = 20;

const ShowInterestPage = () => {

  const titleRef = useRef(null);
  const lineBesideTitle = useRef(null);

  const [titleWidth, setTitleWidth] = useState(0);

  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(null);
  const [errorNameMessage, setErrorNameMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [number, setNumber] = useState("");

  const [submitStatus, setSubmitStatus] = useState(null);

  const changeName = ({ target: { value } }) => {
    // console.log(value);
    setName(value);
    if (!value) {
      setIsNameValid(false);
      setErrorNameMessage(NoNameMessage);
      return;
    } else {
      setIsNameValid(true);
      setErrorNameMessage("");
    }
  }

  const changeEmail = ({ target: { value } }) => {
    console.log(value, !value);
    setEmail(value);
    if (!value) {
      setErrorEmailMessage(NoEmailMessage);
      setIsEmailValid(false);
      return;
    } else {
      let e = validatedEmail(value);
      if (!e) {
        setErrorEmailMessage(`Email cannot be ${value}`);
        setIsEmailValid(false);
        return;
      } else {
        setIsEmailValid(true);
        setErrorEmailMessage("");
      }
    }

  }

  // const changeNumber = ({ target: { value } }) => {
  //   // console.log(value);
  //   setNumber(value);
  // }

  // const validatedNumber = (num) => {
  //   if (isNaN(num)) {
  //     return null;
  //   }
  //   return true;
  // }

  const submitForm = async (event) => {
    event.preventDefault();
    // console.log({ name, email, number });

    let n = name;
    let e = validatedEmail(email);
    // let p = validatedNumber(number);

    if (!n) {
      // const res = <div dangerouslySetInnerHTML={{
      //   __html: capitalizeFirstThreeCharacters('Please share your name with us.')
      // }}>
      // </div>
      // toast.error(res);
      setIsNameValid(false);
      setErrorNameMessage(NoNameMessage);
      return;
    } else {
      setIsNameValid(true);
      setErrorNameMessage("");
    }

    if (!email) {
      // const res = <div dangerouslySetInnerHTML={{
      //   __html: capitalizeFirstThreeCharacters('Provide your email for updates.')
      // }}>
      // </div>
      // toast.error(res);
      setIsEmailValid(false);
      setErrorEmailMessage(NoEmailMessage);
      return;
    } else {
      setIsEmailValid(true);
      setErrorEmailMessage("");
    }

    if (!e) {
      // const res = <div dangerouslySetInnerHTML={{
      //   __html: capitalizeFirstThreeCharacters(`Email cannot be ${email}`)
      // }}>
      // </div>
      // toast.error(res);
      setErrorEmailMessage(`Email cannot be ${email}`);
      setIsEmailValid(false);
      return;
    } else {
      setIsEmailValid(true);
      setErrorEmailMessage("");
    }

    // if (!p) {
    //   toast.error(`Number cannot be "${number}"`);
    //   return;
    // }

    setSubmitStatus(asyncStatuses.LOADING);
    try {
      const response = await axios.post("/confluence/interests/", { email, name });
      // console.log(response);
      const successResponse = <div dangerouslySetInnerHTML={{
        __html: capitalizeFirstThreeCharacters('Thank you for showing interest!')
      }}>
      </div>
      toast.success(successResponse);
      setSubmitStatus(asyncStatuses.SUCCESS);
      setName("");
      setEmail("");
      setNumber("");
    } catch (err) {
      console.error(err);
      const err_ = <div dangerouslySetInnerHTML={{
        __html: capitalizeFirstThreeCharacters("We're sorry. Something went wrong.")
      }}>
      </div>
      toast.error(err_);
      setSubmitStatus(asyncStatuses.FAILED);
    }
  }

  useEffect(() => {
    if (titleRef.current) {
      const computedStyle = window.getComputedStyle(titleRef.current);
      const width = Number(computedStyle.width.replace('px', ''));
      console.log({ computedStyle, width });

      if (lineBesideTitle.current) {
        const computedStyle = window.getComputedStyle(lineBesideTitle.current);
        const displayValue = computedStyle.display;
        if (displayValue === 'none') {
          setTitleWidth(width);
        } else {
          setTitleWidth(width);
        }
      } else {
        setTitleWidth(width);
      }
    }
  }, []);

  return (
    <>
      <section className={`d-flex justify-content-center align-items-center ${styles.container}`}>
        <form onSubmit={submitForm}>
          <div className={`d-flex flex-column justify-content-around align-items-center ${styles['text-container']}`}>
            <div className={`d-flex flex-column justify-content-center align-items-center ${styles['title-and-quote']}`}>
              <div className='d-flex justify-content-center align-items-center' style={{ gap: GAP }}>
                <img ref={lineBesideTitle} src={line} alt="line" style={{ transform: "rotate(180deg)" }} />
                <h3 ref={titleRef} className='felix-font-family text3 confluence_text'>The Confluence Of Consciousness</h3>
                <img src={line} alt="line" />
              </div>
              <div
                style={titleWidth !== 0 ? {
                  width: titleWidth
                } : null}
                className={styles['form-container']}
              >
                <div className='row'>
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <label className='text1' htmlFor="name" >Name</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-start align-items-center">
                    <input
                      className='w-100'
                      type="text"
                      name='name'
                      value={name}
                      onChange={changeName}
                      // style={isNameValid === false ? { borderColor: "red" } : {}}
                      required
                    />
                    {/* {
                      isNameValid === false ? <small className="error-message w-100" dangerouslySetInnerHTML={{
                        __html: capitalizeFirstThreeCharacters(errorNameMessage)
                      }}></small> : <></>
                    } */}
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <label className='text1' htmlFor="email">Email</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-start align-items-center">
                    <input 
                      className='w-100' 
                      type="email" 
                      name='email' 
                      value={email} 
                      onChange={changeEmail} 
                      // style={isEmailValid === false ? { borderColor: "red" } : {}} 
                      required
                    />
                    {/* {
                      isEmailValid === false ? <small className="error-message w-100" dangerouslySetInnerHTML={{
                        __html: capitalizeFirstThreeCharacters(errorEmailMessage)
                      }}></small> : <></>
                    } */}
                  </div>
                </div>
                {/* <div className='row'>
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <label className='text1' htmlFor="number" >Contact Number</label>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <input className='w-100' type="text" name='number' value={number} onChange={changeNumber} required />
                  </div>
                </div> */}
              </div>
              <div>
                <>
                  {
                    submitStatus === asyncStatuses.LOADING ?
                      <button disabled className='d-flex gap-2 justify-content-center align-items-center'>
                        <h5 className='cursor-pointer text1' style={{ color: "#bcbcbc", textTransform: "uppercase" }}>Submitting</h5>
                        <Spinner size='sm' />
                      </button>
                      :
                      <button className='d-flex justify-content-center align-items-center w-100' type='submit'>
                        <h5 style={{ textTransform: "uppercase" }} className='cursor-pointer text1'>
                          Submit
                        </h5>
                      </button>
                  }
                </>
                <div className={`faded-underline`}></div>
              </div>
            </div>

          </div>
        </form>
      </section >
      {/* <AvailableIn /> */}
      <Footer />
    </>
  )
}

export default ShowInterestPage