import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentHeaderTitle: ""
}

const utilitySlices = createSlice({
    name: "utilities",
    initialState: initialState,
    reducers: {
        changeHeaderTitle(state, action) {
            const { payload } = action;
            state.currentHeaderTitle = payload;
        }
    }
});

export const { changeHeaderTitle } = utilitySlices.actions;

export default utilitySlices.reducer;