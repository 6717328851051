import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { CgMenu } from "react-icons/cg";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
// import LoginModal from "components/Auth/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import * as authSlices from "../../reducer/authSlices";


const NavLink = ({ title, route }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const [hover, setHover] = useState(null);

    return (
        <Nav.Link
            className={`text-center py-2 mx-xl-2 ${param1 === route ? "border-primary" : ""
                }`}
            onClick={() => {
                navigate(`/${route}`);
            }}
            onMouseEnter={() => setHover(route)}
            onMouseLeave={() => setHover(null)}
            style={{
                color:
                    hover || hover == ""
                        ? hover === route
                            ? "red"
                            : "black"
                        : param1 === route
                            ? "red"
                            : "black",
            }}
        >
            {title}
        </Nav.Link>
    );
};

const NavBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showForm, setShowForm] = useState("login");
    const [accHover, setAccHover] = useState(false);

    const [showAccountDropdown, setShowAccountDropdown] = useState(false);
    const toggleAccountDropdown = () => {
        setAccHover(!accHover);
        setShowAccountDropdown(!showAccountDropdown);
    };

    const navbarItems = [
        { id: 1, title: "Inspirations", route: "inspirations" },
        { id: 2, title: "Brands", route: "brands" },
        { id: 3, title: "Our Professionals", route: "our-professionals" },
        { id: 4, title: "Why Choose Us?", route: "why-choose-us" }
    ];

    const handleLogout = async () => {
        await dispatch(authSlices.userLogout());
        // const response = await authSlices.userLogout();
        // if (response) {
        //   navigate("/");
        // }
    };

    return (
        <>
            <Navbar
                expand="lg"
                fixed="top"
                className="bg-slate border-bottom border-dark"
            >
                <Container fluid className="px-5 py-2">
                    <Navbar.Brand href="/" className="d-flex align-items-center">
                        <h4 className="text-primary"><b>Confluence of Con</b></h4>
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="navbarScroll"
                        className="focus-none p-1 border-0"
                    >
                        <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" />
                    </Navbar.Toggle>

                    <Navbar.Collapse
                        id="navbarScroll"
                        className={
                            large ? "d-flex justify-content-end" : "bg-extradark pb-4"
                        }
                        style={{ borderRadius: 10 }}
                    >
                        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                            <Nav
                                className={`${large
                                    ? "ms-auto my-2 my-lg-0 font-inter"
                                    : "my-2 my-lg-0 font-inter"
                                    }`}
                                navbarScroll
                                style={{ fontWeight: 600, fontSize: "14px" }}
                            >
                                {navbarItems.map((item, i) => (
                                    <div key={i} className="ms-3">
                                        <NavLink title={item?.title} route={item?.route} />
                                    </div>
                                ))}
                                {!isAuthenticated ? (
                                    param1 !== "login" && (
                                        <Button
                                            className="bg-primary primary-button border border-primary ms-3 px-4"
                                            onClick={() => {
                                                setShowLoginModal(!showLoginModal);
                                            }}
                                            style={{ width: 100, borderRadius: 20 }}
                                        >
                                            Login
                                        </Button>
                                    )
                                ) : (
                                    <NavDropdown
                                        title="Account"
                                        className="mx-xl-2 text-black"
                                    >
                                        <NavDropdown.Item
                                            onClick={() => navigate("/account/profile")}
                                            className={`py-2 px-xl-2 ${accHover === "profile" ? "text-primary" : "text-black"
                                                }`}
                                            onMouseEnter={() => setAccHover("profile")}
                                            onMouseLeave={() => setAccHover(null)}
                                            style={{ fontWeight: 600, fontSize: "14px" }}
                                        >
                                            Profile
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={handleLogout}
                                            className={`py-2 px-xl-2 ${accHover === "logout" ? "text-primary" : "text-black"
                                                }`}
                                            onMouseEnter={() => setAccHover("logout")}
                                            onMouseLeave={() => setAccHover(null)}
                                            style={{ fontWeight: 600, fontSize: "14px" }}
                                        >
                                            Logout
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                )}
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* <LoginModal
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                showForm={showForm}
                setShowForm={setShowForm}
            /> */}
        </>
    );
};

const styles = {
    parentContainer: {
        padding: "40px 100px",
    },
    parentContainerLessPadding: {
        padding: "30px 100px 0px 100px",
    },
    mobileParentContainer: {
        padding: "20px 10px",
    },
};

export default NavBar;
