import React, { useRef } from 'react';
import styles from "./HomeLoader.module.css";
import { black_white_spiral, blue_eye } from 'assets';
import Home from './Home';
import { EYE_IMAGE_ID, H1_ID, SPIRAL_IMAGE_ID } from 'utilities/selectors';
import { useParentContext } from 'store/ParentContext';

const SPIRAL_GOES_OUT_TIME = 3000;
const EYE_COMES_IN_TIME = 3500;

const HomeLoader = () => {

    const { isAnimationOver, setOpacityForAvailableIn, playAudio, downloadModalRef, HomePageRef, spiral_img_ref, timeoutRef, loaderContainerRef, tapAnywhereTextRef } = useParentContext();

    const onMouseEnterSpiral = (e) => {
        // console.log(e);
        // e.target?.classList?.remove?.(`${styles.active_removed}`);
        playAudio();
        tapAnywhereTextRef.current?.classList.add(`${styles['scale-down']}`);
        spiral_img_ref.current?.classList?.add?.(`${styles.active}`);
        timeoutRef.current = setTimeout(() => {
            let el = document.querySelector(`#${EYE_IMAGE_ID}`);
            if (el) {
                el.classList.add(`${styles.active_animation_1}`);
            }
            el = document.querySelector(`#${SPIRAL_IMAGE_ID}`);
            if (el) {
                el.classList.add('d-none');
                // setOpacityForAvailableIn(0);
            }
            setTimeout(() => {
                let h1 = document.querySelector(`#${H1_ID}`);
                let eye = document.querySelector(`#${EYE_IMAGE_ID}`);
                if (h1 && eye) {
                    h1.classList.remove(`text-black`);
                    h1.classList.remove(`bg-black`);
                    h1.classList.add(`${styles.active}`);
                    eye.classList.add(`${styles.active_animation_2}`);
                }
                setTimeout(()=> {
                    downloadModalRef.current?.classList?.remove?.("d-none");
                }, EYE_COMES_IN_TIME + 1000)
                setTimeout(() => {
                    loaderContainerRef.current.classList.add(`${styles.fade_out}`);
                }, EYE_COMES_IN_TIME + 2000);
            }, EYE_COMES_IN_TIME);
        }, SPIRAL_GOES_OUT_TIME);
    }

    const onMouseLeaveSpiral = (e) => {
        // console.log(e);
        e.target?.classList?.remove?.(`${styles.active}`);
        clearTimeout(timeoutRef.current);
        // e.target?.classList?.add?.(`${styles.active_removed}`);
    }

    return (
        <>
            <section ref={loaderContainerRef} className={`d-flex flex-column gap-4 justify-content-center align-items-center ${styles.container} ${isAnimationOver ? "d-none" : ""}`}>
                <img
                    src={black_white_spiral}
                    alt="spiral"
                    className={`${styles.img_spiral}`}
                    id={SPIRAL_IMAGE_ID}
                    ref={spiral_img_ref}
                    onClick={onMouseEnterSpiral}
                    // onMouseLeave={onMouseLeaveSpiral}
                />
                <img
                    src={blue_eye}
                    alt="eye"
                    id={EYE_IMAGE_ID}
                    className={`${styles.img_blue_eye}`}
                />
                <h1
                    className={`${styles.h1} text2`}
                    id={H1_ID}
                >
                    The Confluence Of Consciousness
                </h1>
                <p ref={tapAnywhereTextRef} className={`${styles['touch-anywhere']}`}>
                    <small className='text8'>Tap on the Vortex</small>
                </p>
            </section>
            <Home ref={HomePageRef} className={`${isAnimationOver ? "" : "d-none"}`} />
        </>
    );
}

export default HomeLoader