import React, { useEffect, useRef } from 'react'
import styles from "./NewNavbar.module.css";
import { logo, main_animation, main_animation_video } from 'assets';
import { Link, useLocation } from 'react-router-dom';
import { FULL_WEBSITE_BG, NAVBAR_ID } from 'utilities/selectors';
import AvailableIn from 'components/Common/AvailableIn';
import { LS_KEY_FOR_USER_DOWNLOADED_PDF, useParentContext } from 'store/ParentContext';
import DownloadModal from 'components/Home/DownloadModal';


const INTERVAL_FOR_DOWNLOAD_MODAL = isNaN(Number(process.env.REACT_APP_DOWNLOAD_MODAL_INTERVAL)) ? 30000 : Number(process.env.REACT_APP_DOWNLOAD_MODAL_INTERVAL);
console.log({ INTERVAL_FOR_DOWNLOAD_MODAL });


const NewNavbar = () => {

    const { navbarShouldBeVisible, setNavbarVisibility, isAnimationOver, downloadModalRef, hideDownloadModal, downloadModalIntervalRef } = useParentContext();

    const location = useLocation();
    const pathname = location.pathname;

    const rightNavRef = useRef(null);

    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

        const activate = (selector) => {
            const domNode = document.querySelector(selector);
            if (domNode) {
                // console.dir(rightNavRef.current);
                rightNavRef.current?.childNodes?.forEach(node => {
                    node.classList.remove(`${styles.active}`);
                })
                domNode.classList.add(`${styles.active}`);
            }
        }

        switch (pathname) {
            case "/": {
                activate(".home");
                break;
            }
            case "/quotes": {
                activate(".quotes");
                break;
            }
            case "/show-interest": {
                activate(".show-interest");
                break;
            }
            default: {
                activate(".home");
            }
        }
    }, [location.key, pathname]);

    useEffect(() => {
        if (pathname === "/") {
            if (isAnimationOver)
                setNavbarVisibility(true);
            else
                setNavbarVisibility(false);
        } else {
            setNavbarVisibility(true);
        }
    }, [isAnimationOver, pathname, setNavbarVisibility]);

    useEffect(() => {
        const showDownloadModalIfUserHasNotDownloadedPdf = () => {
            let hasUserDownloaded = localStorage.getItem(LS_KEY_FOR_USER_DOWNLOADED_PDF) === 'true';
            console.log({ hasUserDownloaded });
            if (pathname === "/" && isAnimationOver && !hasUserDownloaded) {
                downloadModalRef?.current?.classList?.remove('d-none');
            } else if (pathname !== "/" && !hasUserDownloaded) {
                downloadModalRef?.current?.classList?.remove('d-none');
            }
        }

        downloadModalIntervalRef.current = setInterval(() => {
            showDownloadModalIfUserHasNotDownloadedPdf();
            console.log("is");
        }, INTERVAL_FOR_DOWNLOAD_MODAL);

        return () => {
            clearInterval(downloadModalIntervalRef?.current);
            console.log("cleared");
        };
    }, [isAnimationOver, downloadModalRef, downloadModalIntervalRef]);

    return (
        <>
            <nav id={NAVBAR_ID} className={`d-flex align-items-center ${styles.container} ${navbarShouldBeVisible ? "" : "d-none"}`}>
                <div className={`d-flex justify-content-center align-items-center ${styles['brand-container']}`}>
                    <a href="/"><img src={logo} alt="Logo" /></a>
                </div>

                <div ref={rightNavRef} className={`d-flex justify-content-between align-items-center ${styles['nav-links-container']}`}>
                    <div className={`${styles['nav-link-item']} home`}>
                        <Link className='text1' to={"/"}>Home</Link>
                        <div></div>
                    </div>
                    <div className={`${styles['nav-link-item']} quotes`}>
                        <Link className='text1' to={"/quotes"}>Quotes</Link>
                        <div></div>
                    </div>
                    <div className={`${styles['nav-link-item']} show-interest`}>
                        <Link className='text1' to={"/show-interest"}>Show Interest</Link>
                        <div></div>
                    </div>
                    {/* <div className={`${styles['nav-link-item']} show-interest`}>
                        <Link to={"test"}>Test</Link>
                        <div></div>
                    </div> */}
                </div>
            </nav>
            <img
                id={FULL_WEBSITE_BG}
                src={main_animation}
                alt="Background Video"
                className={`${styles['background-img']} ${navbarShouldBeVisible ? "" : "d-none"}`}
            />
            {/* <video id={FULL_WEBSITE_BG} autoPlay loop muted
                className={`${styles['background-img']} ${navbarShouldBeVisible ? "" : "d-none"}`}
            >
                <source src={main_animation_video} type="video/webm" />
                Your browser does not support the video tag.
            </video> */}
            <AvailableIn />
            <DownloadModal ref={downloadModalRef} hideDownloadModal={hideDownloadModal} className={"d-none"} />
        </>
    )
}

export default NewNavbar