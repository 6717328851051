import React, { useEffect, useState } from 'react';
import styles from "./Quotes.module.css";
import { line, quote_left, quote_right } from 'assets';
import { Link } from 'react-router-dom';
// import AvailableIn from 'components/Common/AvailableIn';
import axios from 'axios';
import { asyncStatuses } from 'store/enums';
import { Spinner } from 'react-bootstrap';
import Footer from 'components/Common/Footer';


export const capitalizeFirstThreeCharacters = (text, add_quotes = false) => {
    let all_texts = text.split(" ");
    let ft = "";

    if (typeof text === "string") {
        for (let t of all_texts) {
            if (t.length > 3) {
                t = `<strong>${t.substring(0, 3)}</strong>${t.slice(3)}`
            }
            ft += ` ${t}`;
        }

        if (add_quotes) {
            return `"${ft}"`;
        }
        return ft;
    }

    if (add_quotes) {
        return `"${text}"`;
    }

    return text;
}

const QuotesPage = () => {

    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const [fetchedQuotes, setFetchedQuotes] = useState(null);
    const [status, setStatus] = useState(null);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);

    const handleTouchStart = (e) => {
        setTouchStartX(e.targetTouches[0].clientX);
        console.log(e.targetTouches[0].clientX)
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX - touchEndX > 50) {
            nextQuote();
        }

        if (touchEndX - touchStartX > 50) {
            prevQuote();
        }
    };

    const nextQuote = (e) => {
        setCurrentQuoteIndex((c) => {
            let n = (c + 1) % fetchedQuotes.length;
            return n;
        });
    }

    const prevQuote = (e) => {
        setCurrentQuoteIndex((c) => {
            let n = (c - 1);
            if (n < 0) {
                return fetchedQuotes.length - 1;
            }
            // console.log(n);
            return n;
        });
    }

    useEffect(() => {
        const fetchQuotes = async () => {
            setStatus(asyncStatuses.LOADING);
            try {
                const response = await axios.get("/confluence/quotes/");
                if (Array.isArray(response.data)) {
                    setFetchedQuotes(response.data);
                    setStatus(asyncStatuses.SUCCESS);
                }
            } catch (e) {
                console.error(e);
                setStatus(asyncStatuses.FAILED)
            }
        }
        fetchQuotes()
    }, []);

    return (
        <>
            <section className={`d-flex justify-content-center align-items-center ${styles.container}`}>

                <div className={`d-flex flex-column justify-content-around align-items-center ${styles['text-container']}`}>
                    <div>
                        <h5 className='text1'>Coming Soon</h5>
                    </div>
                    <div className={`d-flex flex-column justify-content-center align-items-center ${styles['title-and-quote']}`}>
                        <div className='d-flex justify-content-center align-items-center gap-4'>
                            <img src={line} alt="line" style={{ transform: "rotate(180deg)" }} />
                            <h3 className='felix-font-family text3 confluence_text'>The Confluence Of Consciousness</h3>
                            <img src={line} alt="line" />
                        </div>
                        <div
                            className={`d-flex justify-content-center align-items-center ${styles['quote-container']}`}
                        // onClick={nextQuote}
                        >
                            <div className={`${styles['left-quote-button']}`} onClick={prevQuote}>
                                <img src={quote_left} alt="Previous Quote Button" />
                            </div>
                            {
                                fetchedQuotes?.[currentQuoteIndex]?.content
                                    ?
                                    <h5 className='text7' style={{ maxWidth: 738 }}
                                        dangerouslySetInnerHTML={{
                                            __html: capitalizeFirstThreeCharacters(fetchedQuotes[currentQuoteIndex]?.content)
                                        }}
                                        onTouchStart={handleTouchStart}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                    >
                                    </h5>
                                    :
                                    fetchedQuotes?.[currentQuoteIndex]?.quote_gif
                                        ?
                                        <img
                                            src={fetchedQuotes[currentQuoteIndex].quote_gif}
                                            alt=""
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleTouchEnd}
                                        />
                                        :
                                        status === asyncStatuses.LOADING
                                            ?
                                            <Spinner size='sm' />
                                            :
                                            status === asyncStatuses.FAILED
                                                ?
                                                <p className='text-danger'><em>Failed to load quotes, please reload the page.</em></p>
                                                :
                                                <></>
                            }
                            <div className={`${styles['right-quote-button']}`} onClick={nextQuote}>
                                <img src={quote_right} alt="Next Quote Button" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5 className='text1'>
                            <Link to={"/show-interest"}>Interested</Link>
                        </h5>
                        <div className={`faded-underline`}></div>
                    </div>
                </div>

            </section>
            {/* <AvailableIn /> */}
            <Footer />
        </>
    )
}

export default QuotesPage;